import React from 'react'
import { Box, Flex, Image, Link } from '@chakra-ui/react'
import { Container } from 'components'

const Footer = () => {
  return (
    <Container my={32}>
      <Flex direction="column" align="center" justify="center">
        <Image src="/img/logo.svg" />
        <Flex mt={6} gap={{ base: 6, md: 12 }}>
          <Link>Legal</Link>
          <Link>Support</Link>
          <Link>FAQs</Link>
        </Flex>
        <Box mt={6}>2024 Norpayswap | All rights reserved</Box>
      </Flex>
    </Container>
  )
}

export default Footer
