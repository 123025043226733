import React, { useEffect, useState } from 'react'
import { Container, ContainerBox, Loader } from 'components'
import { Box, Heading, SimpleGrid } from '@chakra-ui/react'
import { GlobalStatistics } from 'types/statistics'
import { useApi } from 'hooks/useApi'

const AdminDashboard = () => {
  const { getGlobalStatistics } = useApi()
  const [globalStatistics, setGlobalStatistics] = useState<GlobalStatistics>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    getGlobalStatistics().then(r => {
      setGlobalStatistics(r)
      setIsLoading(false)
    })
  }, [])

  const statistics = [
    { label: 'Total Users', render: (ms: GlobalStatistics) => ms.totalUsers },
    { label: 'Total Cards', render: (ms: GlobalStatistics) => ms.totalCards },
    { label: 'Activated Cards', render: (ms: GlobalStatistics) => ms.activatedCards },
    {
      label: 'Merchants Total Balance',
      render: (ms: GlobalStatistics) => `${ms.totalBalance / 100} USD`,
    },
    {
      label: 'Merchants Deposited',
      render: (ms: GlobalStatistics) => `${ms.creditBalance / 100} USD`,
    },
    {
      label: 'Merchants Spent',
      render: (ms: GlobalStatistics) => `${(ms.debitBalance / 100) * -1} USD`,
    },
  ]

  return (
    <Container mt={12}>
      <Heading>Dashboard</Heading>
      {isLoading && <Loader />}
      {!isLoading && globalStatistics && (
        <SimpleGrid columns={{ base: 1, lg: 3 }} gap={3} mt={8}>
          {statistics.map(s => (
            <ContainerBox>
              <Box>{s.label}</Box>
              <Heading size="lg">{s.render(globalStatistics)}</Heading>
            </ContainerBox>
          ))}
        </SimpleGrid>
      )}
    </Container>
  )
}

export default AdminDashboard
