import React, { useState } from 'react'
import { Box, Flex, FormControl, FormHelperText, FormLabel, Input, Stack } from '@chakra-ui/react'
import { Button, Loader } from 'components'
import { useApi } from 'hooks/useApi'
import { Manager, ResponseManager } from 'types/manager'

interface Props {
  merchantId: string
  existingManager?: ResponseManager
  onSuccess: () => void
}

const ManagerForm = ({ merchantId, existingManager, onSuccess }: Props) => {
  const { createManager, updateManager } = useApi()

  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState<Manager>({
    address: existingManager ? existingManager.address : '',
    name: existingManager ? existingManager.name : '',
    surname: existingManager ? existingManager.surname : '',
    email: existingManager ? existingManager.email : '',
    phoneCode: existingManager ? existingManager.phoneCode : '',
    phoneNumber: existingManager ? existingManager.phoneNumber : '',
    merchantId,
  })

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    try {
      setIsLoading(true)
      if (existingManager) {
        await updateManager(existingManager.id, {
          ...formData,
          phoneCode: parseInt(formData.phoneCode),
        })
      } else {
        await createManager({ ...formData, phoneCode: parseInt(formData.phoneCode) })
      }
      onSuccess()
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={4}>
        <FormControl isRequired>
          <FormLabel>Address</FormLabel>
          <Input
            type="text"
            name="address"
            placeholder="0x"
            value={formData.address}
            onChange={handleChange}
          />
          <FormHelperText>Ethereum address of the manager</FormHelperText>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Name</FormLabel>
          <Input type="text" name="name" value={formData.name} onChange={handleChange} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Surname</FormLabel>
          <Input type="text" name="surname" value={formData.surname} onChange={handleChange} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input type="email" name="email" value={formData.email} onChange={handleChange} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Phone Number</FormLabel>
          <Flex gap={3}>
            <Box fontSize="2xl">+</Box>
            <Input
              type="number"
              name="phoneCode"
              placeholder="Code"
              maxW="100px"
              value={formData.phoneCode}
              onChange={handleChange}
            />
            <Input
              type="number"
              name="phoneNumber"
              placeholder="Number"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </Flex>
        </FormControl>

        <Button w="full" type="submit" mt={6}>
          Submit
        </Button>
      </Stack>
    </form>
  )
}

export default ManagerForm
