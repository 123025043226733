import { writeContract, readContract, waitForTransaction } from '@wagmi/core'
import { useAccount, erc20ABI, useToken } from 'wagmi'

interface Props {
  tokenAddress: string
}

export const useTokenApproval = ({ tokenAddress }: Props) => {
  const { address: account } = useAccount()
  const { data: tokenData } = useToken({ address: tokenAddress as any })

  const checkApproval = async (spenderAddress: string, amount: bigint) => {
    if (!account || !tokenData) {
      return
    }
    const allowance = await readContract({
      address: tokenAddress as any,
      abi: erc20ABI,
      functionName: 'allowance',
      args: [account, spenderAddress as any],
    })
    if (allowance > amount) {
      return true
    }
    return false
  }

  const approve = async (spenderAddress: string, amount: bigint) => {
    const { hash } = await writeContract({
      address: tokenAddress as any,
      abi: erc20ABI,
      functionName: 'approve',
      args: [spenderAddress as any, amount],
    })
    await waitForTransaction({ hash })
  }

  const approveIfNotApproved = async (spenderAddress: string, amount: bigint) => {
    const isApproved = await checkApproval(spenderAddress, amount)
    if (!isApproved) {
      await approve(spenderAddress, amount)
    }
  }

  return { checkApproval, approve, approveIfNotApproved }
}
