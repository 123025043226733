import React from 'react'
import { Background, Footer, SidebarLayout, RequireAuth } from 'components'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {
  ApiAccessPage,
  Dashboard,
  MerchantPage,
  MerchantsPage,
  UserPage,
  CardTypesPage,
  UsersPage,
  CardFeesPage,
} from 'pages'

function App() {
  return (
    <BrowserRouter>
      <Background />
      <SidebarLayout>
        <Routes>
          <Route path="/" element={<RequireAuth element={<Dashboard />} />} />
          <Route path="/merchants" element={<RequireAuth element={<MerchantsPage />} />} />
          <Route path="/merchants/:id" element={<RequireAuth element={<MerchantPage />} />} />
          <Route path="/api" element={<RequireAuth element={<ApiAccessPage />} />} />
          <Route path="/card-types" element={<RequireAuth element={<CardTypesPage />} />} />
          <Route path="/card-fees" element={<RequireAuth element={<CardFeesPage />} />} />
          <Route path="/users" element={<RequireAuth element={<UsersPage />} />} />
          <Route path="/users/:id" element={<RequireAuth element={<UserPage />} />} />
        </Routes>
        <Footer />
      </SidebarLayout>
    </BrowserRouter>
  )
}

export default App
