import React, { useEffect, useState } from 'react'
import {
  Button,
  ConfirmationButton,
  Container,
  ContainerBox,
  Loader,
  Modal,
  Table,
} from 'components'
import { Box, Flex, Heading, useDisclosure } from '@chakra-ui/react'
import { ResponseMerchant } from 'types/merchant'
import { useApi } from 'hooks/useApi'
import MerchantForm from './components/MerchantForm'

const MerchantsPage = () => {
  const { getAllMerchants, disableMerchant, enableMerchant } = useApi()
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()
  const [merchants, setMerchants] = useState<ResponseMerchant[]>()
  const [editedMerchant, setEditedMerchant] = useState<ResponseMerchant>()
  const [isLoading, setIsLoading] = useState(true)

  const refreshMerchants = () => {
    setIsLoading(true)
    getAllMerchants().then(r => {
      setMerchants(r)
      setIsLoading(false)
    })
  }

  const handleCreate = () => {
    setEditedMerchant(undefined)
    onModalOpen()
  }

  const handleEdit = (merchant: ResponseMerchant) => {
    setEditedMerchant(merchant)
    onModalOpen()
  }

  const handleDisable = async (id: string) => {
    setIsLoading(true)
    await disableMerchant(id)
    refreshMerchants()
  }

  const handleEnable = async (id: string) => {
    setIsLoading(true)
    await enableMerchant(id)
    refreshMerchants()
  }

  useEffect(refreshMerchants, [])

  return (
    <>
      <Container mt={12}>
        <Heading>Merchants</Heading>
        <Button mt={6} onClick={handleCreate}>
          Add Merchant
        </Button>
        <ContainerBox mt={6}>
          {isLoading && <Loader />}
          {!isLoading && merchants && merchants.length === 0 && <Box>No merchants to display</Box>}
          {!isLoading && merchants && merchants.length > 0 && (
            <Table
              data={merchants.sort((a, b) => (a.isDisabled && !b.isDisabled ? 1 : -1))}
              columns={[
                {
                  title: 'Name',
                  render: (m: ResponseMerchant) => (
                    <Button
                      variant="link"
                      to={`/merchants/${m.id}`}
                      opacity={m.isDisabled ? 0.6 : undefined}
                    >
                      {m.companyName}
                    </Button>
                  ),
                },
                {
                  title: 'Actions',
                  render: (m: ResponseMerchant) => (
                    <Flex gap={6}>
                      <Button onClick={() => handleEdit(m)} variant="link">
                        Edit
                      </Button>
                      {m.isDisabled && (
                        <Button
                          onClick={() => handleEnable(m.id)}
                          variant="link"
                          colorScheme="green"
                        >
                          Enable
                        </Button>
                      )}
                      {!m.isDisabled && (
                        <ConfirmationButton
                          onConfirm={() => handleDisable(m.id)}
                          modalContent="Are you sure you want to disable this merchant? They will not be able to make requests to the system."
                          variant="link"
                          colorScheme="red"
                        >
                          Disable
                        </ConfirmationButton>
                      )}
                    </Flex>
                  ),
                },
              ]}
            />
          )}
        </ContainerBox>
      </Container>
      <Modal title="New Merchant" isOpen={isModalOpen} onClose={onModalClose}>
        <MerchantForm
          existingMerchant={editedMerchant}
          onSuccess={() => {
            onModalClose()
            refreshMerchants()
          }}
        />
      </Modal>
    </>
  )
}

export default MerchantsPage
