import React, { useState } from 'react'
import { Box, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'
import { Button, Loader } from 'components'
import { useApi } from 'hooks/useApi'
import { useBalance } from 'wagmi'
import { useAuth } from 'providers/AuthProvider'
import { ethers } from 'ethers'
import { TreasuryToken } from 'types/token'
import { useTreasuryContract } from 'hooks/useTreasuryContract'
import { TreasuryChain } from 'types/chain'

interface Props {
  token: TreasuryToken
  treasuryChain: TreasuryChain
  onSuccess: () => void
}

const BalanceRechargeForm = ({ token, treasuryChain, onSuccess }: Props) => {
  const { deposit } = useTreasuryContract({
    tokenAddress: token.address,
    treasuryAddress: treasuryChain.treasuryAddress,
  })
  const { initializeBalanceDeposit, completeBalanceDeposit } = useApi()
  const { account } = useAuth()

  const { data: balanceData } = useBalance({ address: account as any, token: token.address as any })
  const [reference, setReference] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [formData, setFormData] = useState({
    value: '',
  })

  const balance = balanceData
    ? parseFloat(ethers.formatUnits(balanceData.value, balanceData.decimals))
    : undefined

  const amountBigInt =
    token && formData.value ? ethers.parseUnits(formData.value, token.decimals) : undefined

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (!token || !amountBigInt) {
      return
    }
    try {
      setIsLoading(true)
      const initializeResult = await initializeBalanceDeposit(token.id, amountBigInt.toString(10))
      setReference(initializeResult.reference)
      await deposit(amountBigInt, initializeResult.reference)
      try {
        await completeBalanceDeposit(initializeResult.reference)
        onSuccess()
      } catch {
        setIsError(true)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={4}>
        <FormControl isRequired>
          <FormLabel>Deposit Amount ({token.symbol})</FormLabel>
          <Input
            type="number"
            name="value"
            placeholder="0.00"
            value={formData.value}
            onChange={handleChange}
          />
        </FormControl>

        <Box opacity={0.75}>
          Available: {balance !== undefined ? balance.toFixed(2) : '-'} {token.symbol}
        </Box>

        <Button w="full" type="submit">
          Submit
        </Button>

        {isError && (
          <Box bg="red.600" textColor="white" borderRadius="xl" p={4} mt={4}>
            <Box fontSize="lg">An error has occurred</Box>
            <Box mt={3}>
              Something went wrong while confirming transaction. Please save the following deposit
              reference and use it to recover your deposit.
            </Box>
            <Box fontWeight="bold" mt={4}>
              Deposit reference: {reference}
            </Box>
          </Box>
        )}
      </Stack>
    </form>
  )
}

export default BalanceRechargeForm
