import React, { ReactNode } from 'react'
import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react'
import Navbar from './Navbar'
import SidebarContent from './SidebarContent'
import { useAuth } from 'providers/AuthProvider'

const SidebarLayout = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (!user) {
    return (
      <>
        <Navbar onOpen={onOpen} />
        <Box>{children}</Box>
      </>
    )
  }

  return (
    <Box minH="100vh">
      <Box display={{ base: 'none', lg: 'flex' }}>
        <SidebarContent onClose={onClose} />
      </Box>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Navbar onOpen={onOpen} />
      <Box ml={{ base: 0, lg: 72 }}>{children}</Box>
    </Box>
  )
}

export default SidebarLayout
