import React, { useEffect, useState } from 'react'
import { Button, Container, ContainerBox, CopyButton, Loader, Table } from 'components'
import { Box, Heading, useDisclosure } from '@chakra-ui/react'
import { useApi } from 'hooks/useApi'
import { ResponseCardType } from 'types/card'
import { getShortString } from 'utils/formatters'

const CardFeesPage = () => {
  const { getSelfCardFees } = useApi()
  const [cardFees, setCardFees] = useState<ResponseCardType[]>()
  const [isLoading, setIsLoading] = useState(true)

  const refreshCardTypes = () => {
    setIsLoading(true)
    getSelfCardFees().then(r => {
      setCardFees(r)
      setIsLoading(false)
    })
  }

  useEffect(refreshCardTypes, [])

  return (
    <Container mt={12}>
      <Heading>Card Types</Heading>
      <ContainerBox mt={6}>
        {isLoading && <Loader />}
        {!isLoading && cardFees && cardFees.length === 0 && <Box>No card types to display</Box>}
        {!isLoading && cardFees && cardFees.length > 0 && (
          <Table
            columns={[
              {
                title: 'ID',
                render: cf => (
                  <CopyButton value={cf.cardType.id} text={getShortString(cf.cardType.id)} />
                ),
              },
              {
                title: 'Name',
                render: cf => cf.cardType.name,
              },
              {
                title: 'Application type',
                render: cf => <Box textTransform="capitalize">{cf.cardType.applicationType}</Box>,
              },
              {
                title: 'Application Fee',
                render: cf => `${cf.applicationFeeUsd / 100} USD`,
              },
              {
                title: 'Recharge Fee',
                render: cf => `${cf.rechargeFeeUsd / 100} USD`,
              },
            ]}
            data={cardFees}
          />
        )}
      </ContainerBox>
    </Container>
  )
}

export default CardFeesPage
