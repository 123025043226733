import React from 'react'
import { ConnectButton as RainbowConnectButton } from '@rainbow-me/rainbowkit'
import Button from './Button'
import { Box, Circle, Flex, Image } from '@chakra-ui/react'

const ConnectButton = () => {
  return (
    <RainbowConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading'
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated')
        return (
          <Box
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return <Button onClick={openConnectModal}>Connect Wallet</Button>
              }
              if (chain.unsupported) {
                return (
                  <Button colorScheme="red" onClick={openChainModal}>
                    Wrong network
                  </Button>
                )
              }
              return (
                <Flex gap={3}>
                  <Button variant="secondary" onClick={openChainModal}>
                    <Flex align="center" gap={3}>
                      {chain.hasIcon && (
                        <Box
                          background={chain.iconBackground}
                          height="18px"
                          width="18px"
                          borderRadius="xl"
                          overflow="hidden"
                        >
                          {chain.iconUrl && <Image src={chain.iconUrl} w="18px" h="18px" />}
                        </Box>
                      )}
                      <Box display={{ base: 'none', md: 'block' }}>{chain.name}</Box>
                    </Flex>
                  </Button>
                  <Button variant="secondary" onClick={openAccountModal}>
                    <Flex align="center" gap={3}>
                      <Circle bg="green.400" size="12px" />
                      <Flex gap={1}>
                        <Box>{account.displayName}</Box>
                        <Box display={{ base: 'none', md: 'block' }}>
                          {account.displayBalance ? ` (${account.displayBalance})` : ''}
                        </Box>
                      </Flex>
                    </Flex>
                  </Button>
                </Flex>
              )
            })()}
          </Box>
        )
      }}
    </RainbowConnectButton.Custom>
  )
}

export default ConnectButton
