import React from 'react'
import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  TableContainerProps,
} from '@chakra-ui/react'

interface TableColumn {
  render: (obj: any) => any
  title: string
}

interface Props extends TableContainerProps {
  columns: (TableColumn | undefined)[]
  data: any[]
}

const TableComponent = ({ columns, data, ...rest }: Props) => {
  return (
    <TableContainer {...rest}>
      <ChakraTable>
        <Thead>
          <Tr>
            {columns.map(
              column =>
                column && (
                  <Th key={column.title} border="none" textTransform="none" fontSize="none" pl={0}>
                    {column.title}
                  </Th>
                ),
            )}
          </Tr>
        </Thead>
        <Tbody>
          {data.map(record => (
            <Tr key={record.id}>
              {columns.map(
                column =>
                  column && (
                    <Td
                      key={column.title}
                      color={record.isDisabled ? 'gray.400' : undefined}
                      border="none"
                      pl={0}
                    >
                      {column.render(record)}
                    </Td>
                  ),
              )}
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}

export default TableComponent
