import React from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from 'styles/theme'
import { Buffer } from 'buffer'
import { connectorsForWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { bsc, bscTestnet, polygonMumbai } from 'wagmi/chains'
import { injectedWallet } from '@rainbow-me/rainbowkit/wallets'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import AuthProvider from 'providers/AuthProvider'
import App from './App'
import 'index.css'
import '@rainbow-me/rainbowkit/styles.css'
import {
  BSC_RPC_PROVIDER,
  BSC_TESTNET_RPC_PROVIDER,
  POLYGON_MUMBAI_RPC_PROVIDER,
} from 'data/constants'
import GlobalProvider from 'providers/GlobalProvider'

const isProduction = process.env.REACT_APP_PRODUCTION !== 'false'

const availableChains: any = isProduction ? [bsc] : [bscTestnet, polygonMumbai]

const { chains, publicClient } = configureChains(availableChains, [
  jsonRpcProvider({
    rpc: chain => {
      switch (chain.id) {
        case polygonMumbai.id:
          return { http: POLYGON_MUMBAI_RPC_PROVIDER }
        case bscTestnet.id:
          return { http: BSC_TESTNET_RPC_PROVIDER }
        case bsc.id:
          return { http: BSC_RPC_PROVIDER }
        default:
          return null
      }
    },
  }),
])

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [injectedWallet({ chains })],
  },
])

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
})

window.Buffer = window.Buffer || Buffer

ReactDOM.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <AuthProvider>
          <GlobalProvider>
            <ChakraProvider theme={theme}>
              <App />
            </ChakraProvider>
          </GlobalProvider>
        </AuthProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>,
  document.getElementById('root'),
)
