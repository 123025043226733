import React, { useEffect, useState } from 'react'
import { Button, Container, ContainerBox, Loader, Modal } from 'components'
import { Box, Heading, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import { useApi } from 'hooks/useApi'
import BalanceRechargeForm from './BalanceRechargeForm'
import { useChainId } from 'wagmi'
import { useGlobal } from 'providers/GlobalProvider'
import { TreasuryChain } from 'types/chain'
import { MerchantStatistics } from 'types/statistics'

const ManagerDashboard = () => {
  const { getMerchantBalance, getSelfMerchantStatistics } = useApi()
  const chainId = useChainId()
  const { treasuryChains, isLoading: isTreasuryChainsLoading } = useGlobal()

  const [balance, setBalance] = useState<number>()
  const [merchantStatistics, setMerchantStatistics] = useState<MerchantStatistics>()
  const [isBalanceLoading, setIsBalanceLoading] = useState(true)
  const [isStatisticsLoading, setIsStatisticsLoading] = useState(true)
  const [treasuryChain, setTreasuryChain] = useState<TreasuryChain | undefined>()

  const {
    isOpen: isBalanceModalOpen,
    onOpen: onBalanceModalOpen,
    onClose: onBalanceModalClose,
  } = useDisclosure()

  const getTreasuryData = () => {
    if (!treasuryChains) {
      return
    }
    const chain = treasuryChains.find(c => c.chainId === chainId)
    setTreasuryChain(chain)
  }

  const refreshBalance = () => {
    setIsBalanceLoading(true)
    getMerchantBalance().then(r => {
      setBalance(r)
      setIsBalanceLoading(false)
    })
  }

  const refreshStatistics = () => {
    setIsStatisticsLoading(true)
    getSelfMerchantStatistics().then(r => {
      setMerchantStatistics(r)
      setIsStatisticsLoading(false)
    })
  }

  useEffect(() => {
    refreshBalance()
    refreshStatistics()
  }, [])
  useEffect(getTreasuryData, [treasuryChains])

  const statistics = [
    { label: 'Total Users', render: (ms: MerchantStatistics) => ms.totalUsers },
    { label: 'Total Cards', render: (ms: MerchantStatistics) => ms.totalCards },
    { label: 'Activated Cards', render: (ms: MerchantStatistics) => ms.activatedCards },
  ]

  return (
    <Container mt={12}>
      <Heading>Dashboard</Heading>
      {isStatisticsLoading && <Loader />}
      {!isStatisticsLoading && merchantStatistics && (
        <SimpleGrid columns={{ base: 1, lg: 3 }} gap={3} mt={8}>
          {statistics.map(s => (
            <ContainerBox>
              <Box>{s.label}</Box>
              <Heading>{s.render(merchantStatistics)}</Heading>
            </ContainerBox>
          ))}
        </SimpleGrid>
      )}
      {(isBalanceLoading || isTreasuryChainsLoading) && <Loader />}
      {!(isBalanceLoading || isTreasuryChainsLoading) && balance !== undefined && (
        <ContainerBox mt={6}>
          <Box>Merchant Balance</Box>
          <Heading>{balance / 100} USD</Heading>
          <Button onClick={onBalanceModalOpen} mt={6}>
            Deposit Tokens
          </Button>
        </ContainerBox>
      )}
      <Modal title="Deposit Tokens" isOpen={isBalanceModalOpen} onClose={onBalanceModalClose}>
        {treasuryChain && (
          <BalanceRechargeForm
            treasuryChain={treasuryChain}
            token={treasuryChain.tokens[0]}
            onSuccess={() => {
              onBalanceModalClose()
              refreshBalance()
            }}
          />
        )}
      </Modal>
    </Container>
  )
}

export default ManagerDashboard
