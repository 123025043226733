import React, { useEffect, useState } from 'react'
import { Button, Container, ContainerBox, CopyButton, Loader, Modal, Table } from 'components'
import { Box, Heading } from '@chakra-ui/react'
import { useApi } from 'hooks/useApi'
import { ResponseUser } from 'types/user'
import { getShortString } from 'utils/formatters'

const UsersPage = () => {
  const { getSelfUsers } = useApi()
  const [users, setUsers] = useState<ResponseUser[]>()
  const [isLoading, setIsLoading] = useState(true)

  const refreshUsers = () => {
    setIsLoading(true)
    getSelfUsers().then(r => {
      setUsers(r)
      setIsLoading(false)
    })
  }

  useEffect(refreshUsers, [])

  return (
    <>
      <Container mt={12}>
        <Heading>Users</Heading>
        <ContainerBox mt={6}>
          {isLoading && <Loader />}
          {!isLoading && users && users.length === 0 && <Box>No users to display</Box>}
          {!isLoading && users && users.length > 0 && (
            <Table
              columns={[
                {
                  title: 'Name',
                  render: u => (
                    <Button
                      variant="link"
                      to={`/users/${u.id}`}
                      opacity={u.isDisabled ? 0.6 : undefined}
                    >
                      {u.name} {u.surname}
                    </Button>
                  ),
                },
                {
                  title: 'ID',
                  render: u => <CopyButton text={getShortString(u.id)} value={u.id} />,
                },
                {
                  title: 'Address',
                  render: u => <CopyButton text={getShortString(u.address)} value={u.address} />,
                },
              ]}
              data={users}
            />
          )}
        </ContainerBox>
      </Container>
    </>
  )
}

export default UsersPage
