import React from 'react'
import { useAuth } from 'providers/AuthProvider'
import ManagerDashboard from './components/ManagerDashboard'
import AdminDashboard from './components/AdminDashboard'

const Dashboard = () => {
  const { user } = useAuth()

  if (user?.role === 'admin') {
    return <AdminDashboard />
  }

  return <ManagerDashboard />
}

export default Dashboard
