import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  components: {},
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  colors: {},
  styles: { global: { body: { backgroundColor: '#eeeeee' } } },
  fonts: {
    heading: 'DM Sans',
    body: 'DM Sans',
  },
})
