import React, { createContext, useContext, useEffect, useState } from 'react'
import { useApi } from 'hooks/useApi'
import { TreasuryChain } from 'types/chain'
import { useAuth } from './AuthProvider'

type GlobalInfo = {
  treasuryChains?: TreasuryChain[]
  isLoading: boolean
}

type Props = {
  children: any
}

const initialValue = {
  treasuryChains: undefined,
  isLoading: false,
}

const GlobalContext = createContext<GlobalInfo>(initialValue)

export const useGlobal = () => {
  return useContext(GlobalContext)
}

const GlobalProvider = ({ children }: Props) => {
  const { isAuthenticated } = useAuth()
  const { getAvailableChains } = useApi()

  const [treasuryChains, setTreasuryChains] = useState<TreasuryChain[]>()
  const [isLoading, setIsLoading] = useState(false)

  const getData = async () => {
    if (isAuthenticated) {
      setIsLoading(true)
      const chains = await getAvailableChains()
      setTreasuryChains(chains)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [isAuthenticated])

  return (
    <GlobalContext.Provider value={{ treasuryChains, isLoading }}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider
