import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

const ContainerBox = ({ children, ...rest }: BoxProps) => {
  return (
    <Box bg="whiteAlpha.700" p={6} borderRadius="3xl" {...rest}>
      {children}
    </Box>
  )
}

export default ContainerBox
