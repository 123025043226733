import React, { createContext, useContext, useEffect, useState } from 'react'
import { useApi } from 'hooks/useApi'
import { useAccount, useSignMessage } from 'wagmi'
import jwtDecode from 'jwt-decode'
import { User } from 'types/user'

type AuthInfo = {
  account?: string
  user?: User
  isAuthenticated: boolean
  isAuthenticating: boolean
  authenticate: (address: { address: string }) => Promise<void>
}

type Props = {
  children: any
}

const initialValue = {
  account: undefined,
  isAuthenticated: false,
  isAuthenticating: false,
  authenticate: async () => {},
}

const AuthContext = createContext<AuthInfo>(initialValue)

export const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({ children }: Props) => {
  const { createChallenge, verifySignature } = useApi()
  const { signMessageAsync } = useSignMessage()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false)
  const [user, setUser] = useState<User | undefined>()

  const checkSignedInUser = (address: string) => {
    const userString = localStorage.getItem('user')
    if (!userString) {
      return null
    }
    const signedInUser = JSON.parse(userString)
    if (signedInUser?.address !== address) {
      return null
    }
    const token = jwtDecode<any>(signedInUser.token)
    if (!token?.exp) {
      return null
    }
    return token.exp * 1000 > Date.now() ? signedInUser : null
  }

  const handleConnect = async ({ address }: { address? }) => {
    const signedInUser = checkSignedInUser(address)
    if (!signedInUser) {
      try {
        setIsAuthenticating(true)
        const challengeResult = await createChallenge(address)
        const signedMessage = await signMessageAsync({ message: challengeResult.challenge })
        const authenticatedUser = await verifySignature(address, signedMessage)
        localStorage.setItem('user', JSON.stringify(authenticatedUser))
        setUser(authenticatedUser)
        setIsAuthenticated(true)
      } catch (e) {
        console.error(e)
        setIsAuthenticating(false)
      }
    } else {
      setUser(signedInUser)
      setIsAuthenticated(true)
    }
  }

  const handleDisconnect = () => {
    localStorage.removeItem('user')
    setUser(undefined)
    setIsAuthenticated(false)
    window.location.replace('/')
  }

  const { address: account } = useAccount({
    onConnect: handleConnect,
    onDisconnect: handleDisconnect,
  })

  useEffect(() => {
    if (account && user && user.address.toLowerCase() !== account.toLowerCase()) {
      localStorage.removeItem('user')
      window.location.reload()
    }
  }, [account])

  return (
    <AuthContext.Provider
      value={{
        account,
        user,
        isAuthenticated,
        isAuthenticating,
        authenticate: handleConnect,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
