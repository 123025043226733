import React from 'react'
import { Box, Flex, Image } from '@chakra-ui/react'
import { Container, Button } from 'components'
import { ConnectButton } from 'components'
import { useAuth } from 'providers/AuthProvider'

interface Props {
  onOpen: () => void
}

const Navbar = ({ onOpen }: Props) => {
  const { user } = useAuth()

  if (!user) {
    return null
  }

  return (
    <Box bg="whiteAlpha.700" ml={{ base: 0, lg: 72 }}>
      <Container>
        <Flex justifyContent="right" alignItems="center" w="100%" h="70px">
          <Box display={{ base: 'none', lg: 'block' }}>
            <ConnectButton />
          </Box>
          <Box display={{ base: 'block', lg: 'none' }}>
            <Button>
              <Box className="fas fa-bars" onClick={onOpen} />
            </Button>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default Navbar
