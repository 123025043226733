import React, { useState } from 'react'
import { FormControl, FormLabel, Input, Select, Stack } from '@chakra-ui/react'
import { Button, Loader } from 'components'
import { useApi } from 'hooks/useApi'
import { CardType, ResponseCardType } from 'types/card'

interface Props {
  existingCardType?: ResponseCardType
  onSuccess: () => void
}

const CardTypeForm = ({ existingCardType, onSuccess }: Props) => {
  const { createCardType, updateCardType } = useApi()

  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState<CardType>({
    name: existingCardType ? existingCardType.name : '',
    providerCardTypeId: existingCardType ? existingCardType.providerCardTypeId : '',
    applicationType: existingCardType ? existingCardType.applicationType : 'express',
  })

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    try {
      setIsLoading(true)
      if (existingCardType) {
        await updateCardType(existingCardType.id, formData)
      } else {
        await createCardType(formData)
      }
      onSuccess()
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={4}>
        <FormControl isRequired>
          <FormLabel>Card Type Name</FormLabel>
          <Input type="text" name="name" value={formData.name} onChange={handleChange} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Provider Card Type ID</FormLabel>
          <Input
            type="text"
            name="providerCardTypeId"
            value={formData.providerCardTypeId}
            isDisabled={!!existingCardType}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Application Type</FormLabel>
          <Select
            placeholder="Select option"
            name="applicationType"
            value={formData.applicationType}
            onChange={handleChange}
          >
            <option value="express">Express</option>
            <option value="basic">Basic</option>
          </Select>
        </FormControl>

        <Button w="full" type="submit" mt={6}>
          Submit
        </Button>
      </Stack>
    </form>
  )
}

export default CardTypeForm
