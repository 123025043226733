import React from 'react'
import { Button, Loader } from 'components'
import { useAuth } from 'providers/AuthProvider'
import { useConnectModal } from '@rainbow-me/rainbowkit'

const AuthenticateButton = () => {
  const { isAuthenticated, account, isAuthenticating, authenticate } = useAuth()
  const { openConnectModal } = useConnectModal()

  if (!account) {
    return (
      <Button mt={6} w="full" onClick={openConnectModal}>
        Connect wallet
      </Button>
    )
  }

  if (isAuthenticating) {
    return <Loader text="Waiting for authentication signature" />
  }

  if (account && !isAuthenticated) {
    return (
      <Button mt={6} w="full" onClick={() => authenticate({ address: account })}>
        Authenticate
      </Button>
    )
  }

  return null
}

export default AuthenticateButton
