import React, { useEffect, useState } from 'react'
import {
  Button,
  ConfirmationButton,
  Container,
  ContainerBox,
  CopyButton,
  Loader,
  Modal,
  Table,
} from 'components'
import { Box, Divider, Heading, useDisclosure } from '@chakra-ui/react'
import { useApi } from 'hooks/useApi'
import { ResponseMerchantIp, ResponseMerchantKey } from 'types/merchant'
import IpAddressForm from './components/IpAddressForm'

const ApiAccessPage = () => {
  const { getApiKeys, generateApiKey, disableApiKey, getIpAddresses, deleteIpAddress } = useApi()
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()
  const [apiKeys, setApiKeys] = useState<ResponseMerchantKey[]>()
  const [ipAddresses, setIpAddresses] = useState<ResponseMerchantIp[]>()
  const [generatedApiKey, setGeneratedApiKey] = useState<string>()
  const [isApiKeysLoading, setIsApiKeysLoading] = useState(true)
  const [isIpAddressesLoading, setIsIpAddressesLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const refreshApiKeys = () => {
    setIsApiKeysLoading(true)
    getApiKeys()
      .then(r => {
        setApiKeys(r)
        setIsApiKeysLoading(false)
      })
      .catch(err => {
        console.error(err)
        setIsError(true)
      })
  }

  const refreshIpAddresses = () => {
    setIsIpAddressesLoading(true)
    getIpAddresses()
      .then(r => {
        setIpAddresses(r)
        setIsIpAddressesLoading(false)
      })
      .catch(err => {
        console.error(err)
        setIsError(true)
      })
  }

  const handleGenerateKey = () => {
    setIsApiKeysLoading(true)
    generateApiKey()
      .then(r => {
        setGeneratedApiKey(r.apiKey)
        setIsApiKeysLoading(false)
      })
      .catch(err => {
        console.error(err)
        setIsError(true)
      })
  }

  const handleDisableKey = id => {
    setIsApiKeysLoading(true)
    setGeneratedApiKey(undefined)
    disableApiKey(id)
      .then(() => {
        refreshApiKeys()
        setIsApiKeysLoading(false)
      })
      .catch(err => {
        console.error(err)
        setIsError(true)
      })
  }

  const handleDeleteIpAddress = id => {
    setIsIpAddressesLoading(true)
    setGeneratedApiKey(undefined)
    deleteIpAddress(id)
      .then(() => {
        refreshIpAddresses()
        setIsIpAddressesLoading(false)
      })
      .catch(err => {
        console.error(err)
        setIsError(true)
      })
  }

  useEffect(refreshApiKeys, [generatedApiKey])
  useEffect(refreshIpAddresses, [])

  return (
    <>
      <Container mt={12}>
        <Heading>API</Heading>
        <ContainerBox mt={6}>
          <Heading size="md" mb={6}>
            API Keys
          </Heading>
          {isApiKeysLoading && <Loader />}
          {!isApiKeysLoading && apiKeys && apiKeys.length === 0 && <Box>No API keys generated</Box>}
          {!isApiKeysLoading && apiKeys && apiKeys.length > 0 && (
            <Table
              columns={[
                {
                  title: 'Key',
                  render: key => <Box opacity={key.isDisabled ? 0.5 : 1}>{key.preview}***</Box>,
                },
                {
                  title: 'Status',
                  render: key => (key.isDisabled ? 'Disabled' : 'Active'),
                },
                {
                  title: 'Actions',
                  render: key =>
                    !key.isDisabled && (
                      <ConfirmationButton
                        variant="link"
                        colorScheme="red"
                        modalContent={
                          <>
                            <Box>
                              This action will disable the API key and may cause unexpected problems
                              if the key is still used. By performing this action you confirm that
                              the API key is not used in your applications.
                            </Box>
                            <Box mt={3}>Are you sure you want to continue?</Box>
                          </>
                        }
                        onConfirm={() => handleDisableKey(key.id)}
                      >
                        Disable
                      </ConfirmationButton>
                    ),
                },
              ]}
              data={apiKeys.sort((a, b) => (a.isDisabled && !b.isDisabled ? 1 : -1))}
            />
          )}
          {!isApiKeysLoading && !isError && (
            <Button mt={6} onClick={handleGenerateKey}>
              Generate a New API Key
            </Button>
          )}
          {!isApiKeysLoading && generatedApiKey && (
            <Box>
              <Divider my={6} />
              <Box fontWeight="bold">
                This is your New API Key. Please save this value now, since it will not be available
                later.
              </Box>
              <Box bg="purple.100" overflow="auto" p={3} my={2} gap={2}>
                {generatedApiKey}
              </Box>
              <CopyButton text="Copy API Key" value={generatedApiKey} />
            </Box>
          )}
        </ContainerBox>
        <ContainerBox mt={6}>
          <Heading size="md" mb={6}>
            Whitelisted IP Addresses
          </Heading>
          {isIpAddressesLoading && <Loader />}
          {!isIpAddressesLoading && ipAddresses && ipAddresses.length === 0 && (
            <Box>No IP addresses whitelisted</Box>
          )}
          {!isIpAddressesLoading && ipAddresses && ipAddresses.length > 0 && (
            <Table
              columns={[
                {
                  title: 'IP Address',
                  render: ip => <Box>{ip.ipAddress}</Box>,
                },
                {
                  title: 'Actions',
                  render: ip => (
                    <ConfirmationButton
                      variant="link"
                      colorScheme="red"
                      modalContent={
                        <>
                          <Box>
                            This action will delete the IP address and may cause unexpected problems
                            if requests from this IP address are still being sent to the system. By
                            performing this action you confirm that your active applications do not
                            use this IP address.
                          </Box>
                          <Box mt={3}>Are you sure you want to continue?</Box>
                        </>
                      }
                      onConfirm={() => handleDeleteIpAddress(ip.id)}
                    >
                      Disable
                    </ConfirmationButton>
                  ),
                },
              ]}
              data={ipAddresses}
            />
          )}
          {!isIpAddressesLoading && !isError && (
            <Button mt={6} onClick={onModalOpen}>
              Add IP Address
            </Button>
          )}
        </ContainerBox>
        <ContainerBox mt={6}>
          <Heading size="md">API Documentation</Heading>
          <Button mt={6} href={`${process.env.REACT_APP_PROXY_API_URL}/api`}>
            Go to Documentation
          </Button>
        </ContainerBox>
      </Container>
      <Modal title="Whitelist IP address" isOpen={isModalOpen} onClose={onModalClose}>
        <IpAddressForm
          onSuccess={() => {
            onModalClose()
            refreshIpAddresses()
          }}
        />
      </Modal>
    </>
  )
}

export default ApiAccessPage
