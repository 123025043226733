import React, { useState } from 'react'
import { FormControl, FormHelperText, FormLabel, Input } from '@chakra-ui/react'
import { Button, Loader } from 'components'
import { useApi } from 'hooks/useApi'
import { MerchantIp } from 'types/merchant'

interface Props {
  onSuccess: () => void
}

const IpAddressForm = ({ onSuccess }: Props) => {
  const { createIpAddress } = useApi()

  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState<MerchantIp>({
    ipAddress: '',
  })

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    try {
      setIsLoading(true)
      await createIpAddress(formData.ipAddress)
      onSuccess()
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormControl isRequired>
        <FormLabel>IP address</FormLabel>
        <Input type="text" name="ipAddress" value={formData.ipAddress} onChange={handleChange} />
        <FormHelperText>IP address to be whitelisted</FormHelperText>
      </FormControl>

      <Button w="full" type="submit" mt={6}>
        Submit
      </Button>
    </form>
  )
}

export default IpAddressForm
