import { useToast } from '@chakra-ui/react'

const BASE_URL = process.env.REACT_APP_API_URL

export const useApi = () => {
  const toast = useToast()

  const request = async (
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    path: string,
    body?: any,
    formData?: FormData,
  ) => {
    const userString = localStorage.getItem('user')
    const user = userString ? JSON.parse(userString) : null
    const headers = {
      Authorization: user ? `Bearer ${user.token}` : '',
    }
    if (!formData) {
      headers['Content-Type'] = 'application/json'
    }
    let requestBody
    if (body) {
      requestBody = JSON.stringify(body)
    } else if (formData) {
      requestBody = formData
    }
    const response = await fetch(`${BASE_URL}${path}`, {
      method,
      body: requestBody,
      headers,
    })

    if (!response.ok) {
      if (response.status === 400) {
        const err = await response.json()
        toast({
          status: 'error',
          title: err.message,
          duration: 15000,
          isClosable: true,
        })
      } else if (response.status === 401) {
        localStorage.removeItem('user')
        window.location.reload()
      } else {
        toast({
          status: 'error',
          title: 'Something went wrong',
        })
      }
      throw new Error()
    }

    if (!response.headers.get('Content-Type')?.includes('application/json')) {
      return
    }

    const result = await response.json()
    return result
  }

  const createChallenge = async (address: string) =>
    request('POST', `/api/v1/managers/auth/challenge`, { address })

  const verifySignature = async (address: string, signature: string) =>
    request('POST', `/api/v1/auth/verify`, { address, signature })

  const getAvailableChains = async () => request('GET', `/api/v1/chains`)

  const getAllMerchants = async () => request('GET', `/api/v1/merchants`)
  const getMerchant = async id => request('GET', `/api/v1/merchants/${id}`)
  const createMerchant = async merchant => request('POST', `/api/v1/merchants`, merchant)
  const updateMerchant = async (id, merchant) => request('PUT', `/api/v1/merchants/${id}`, merchant)
  const disableMerchant = async id => request('PUT', `/api/v1/merchants/${id}/disable`)
  const enableMerchant = async id => request('PUT', `/api/v1/merchants/${id}/enable`)

  const createManager = async manager => request('POST', `/api/v1/managers`, manager)
  const updateManager = async (id, manager) => request('PUT', `/api/v1/managers/${id}`, manager)
  const disableManager = async id => request('PUT', `/api/v1/managers/${id}/disable`)
  const enableManager = async id => request('PUT', `/api/v1/managers/${id}/enable`)

  const getApiKeys = async () => request('GET', `/api/v1/merchants/api-keys`)
  const generateApiKey = async () => request('POST', `/api/v1/merchants/api-keys`)
  const disableApiKey = async id => request('PUT', `/api/v1/merchants/api-keys/${id}/disable`)

  const getIpAddresses = async () => request('GET', `/api/v1/merchants/ip-addresses`)
  const createIpAddress = async (ipAddress: string) =>
    request('POST', `/api/v1/merchants/ip-addresses`, { ipAddress })
  const deleteIpAddress = async id => request('DELETE', `/api/v1/merchants/ip-addresses/${id}`)

  const disableCard = async id => request('PUT', `/api/v1/cards/${id}/disable`)
  const enableCard = async id => request('PUT', `/api/v1/cards/${id}/enable`)

  const getAllCardTypes = async () => request('GET', `/api/v1/cards/types`)
  const createCardType = async cardType => request('POST', `/api/v1/cards/types`, cardType)
  const updateCardType = async (id, cardType) =>
    request('PUT', `/api/v1/cards/types/${id}`, cardType)
  const disableCardType = async id => request('PUT', `/api/v1/cards/types/${id}/disable`)
  const enableCardType = async id => request('PUT', `/api/v1/cards/types/${id}/enable`)

  const createCardFee = async cardFee => request('POST', `/api/v1/cards/fees`, cardFee)
  const updateCardFee = async (id, cardFee) => request('PUT', `/api/v1/cards/fees/${id}`, cardFee)
  const disableCardFee = async id => request('PUT', `/api/v1/cards/fees/${id}/disable`)
  const enableCardFee = async id => request('PUT', `/api/v1/cards/fees/${id}/enable`)
  const getSelfCardFees = async () => request('GET', `/api/v1/cards/fees/self`)

  const getMerchantBalance = async () => {
    const result = await request('GET', `/api/v1/merchants/balance`)
    return result.balance
  }

  const initializeBalanceDeposit = async (tokenId: string, amount: string) =>
    request('POST', `/api/v1/merchants/balance/recharge/initialize`, { tokenId, amount })

  const completeBalanceDeposit = async (reference: string) =>
    request('POST', `/api/v1/merchants/balance/recharge/complete`, { reference })

  const getSelfUsers = async () => request('GET', `/api/v1/users/self`)
  const getSelfUser = async (id: string) => request('GET', `/api/v1/users/self/${id}`)
  const getUser = async (id: string) => request('GET', `/api/v1/users/${id}`)
  const disableUser = async id => request('PUT', `/api/v1/users/${id}/disable`)
  const enableUser = async id => request('PUT', `/api/v1/users/${id}/enable`)

  const getGlobalStatistics = async () => request('GET', `/api/v1/statistics/global`)
  const getMerchantStatistics = async id => request('GET', `/api/v1/statistics/merchant/${id}`)
  const getSelfMerchantStatistics = async () => request('GET', `/api/v1/statistics/merchant/self`)

  return {
    createChallenge,
    verifySignature,
    getAvailableChains,
    getAllMerchants,
    getMerchant,
    createMerchant,
    updateMerchant,
    disableMerchant,
    enableMerchant,
    createManager,
    updateManager,
    disableManager,
    enableManager,
    getApiKeys,
    generateApiKey,
    disableApiKey,
    getIpAddresses,
    createIpAddress,
    deleteIpAddress,
    disableCard,
    enableCard,
    getAllCardTypes,
    createCardType,
    updateCardType,
    disableCardType,
    enableCardType,
    createCardFee,
    updateCardFee,
    disableCardFee,
    enableCardFee,
    getMerchantBalance,
    initializeBalanceDeposit,
    completeBalanceDeposit,
    getSelfUsers,
    getSelfUser,
    getUser,
    disableUser,
    enableUser,
    getSelfCardFees,
    getGlobalStatistics,
    getMerchantStatistics,
    getSelfMerchantStatistics,
  }
}
