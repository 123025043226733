import React from 'react'
import { Image } from '@chakra-ui/react'

function Background() {
  return (
    <>
      <Image
        position="absolute"
        src="/img/background-circle-1.svg"
        top={0}
        left={0}
        w="1500px"
        mt="-600px"
        ml="-400px"
        zIndex={-1}
      />
      <Image
        position="absolute"
        src="/img/background-circle-2.svg"
        top={0}
        right={0}
        w="1000px"
        mt="-100px"
        ml="-400px"
        display={{ base: 'none', xl: 'block' }}
        zIndex={-1}
      />
    </>
  )
}

export default Background
