import React from 'react'
import { Flex, Heading, Image } from '@chakra-ui/react'
import { Container, ContainerBox, AuthenticateButton } from 'components'
import { useAuth } from 'providers/AuthProvider'

interface Props {
  element: any
  adminRequired?: boolean
}

const RequireAuth = ({ element, adminRequired }: Props) => {
  const { isAuthenticated, user } = useAuth()

  if (!isAuthenticated) {
    return (
      <Container maxW="xl" mt={48}>
        <ContainerBox p={6}>
          <Flex justify="center" align="center" direction="column">
            <Image src="/img/logo.svg" />
            <Heading mt={6} size="md" textAlign="center">
              Please connect to wallet and authenticate
            </Heading>
            <AuthenticateButton />
          </Flex>
        </ContainerBox>
      </Container>
    )
  } else if (adminRequired && user?.role === 'admin') {
    return element
  } else if (!adminRequired) {
    return element
  } else {
    return null
  }
}

export default RequireAuth
