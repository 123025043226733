import React, { useEffect, useState } from 'react'
import {
  Button,
  ConfirmationButton,
  Container,
  ContainerBox,
  CopyButton,
  Loader,
  Table,
} from 'components'
import { Box, Flex, Heading, SimpleGrid } from '@chakra-ui/react'
import { useApi } from 'hooks/useApi'
import { ResponseUser } from 'types/user'
import { getShortString } from 'utils/formatters'
import { useAuth } from 'providers/AuthProvider'
import { useParams } from 'react-router-dom'
import { ResponseCard } from 'types/card'

const UserPage = () => {
  const { id: userId } = useParams()
  const { user: authUser } = useAuth()
  const { getUser, getSelfUser, disableCard, enableCard } = useApi()
  const [user, setUser] = useState<ResponseUser>()
  const [isLoading, setIsLoading] = useState(true)

  const refreshUser = () => {
    if (!authUser || !userId) {
      return
    }
    setIsLoading(true)
    if (authUser.role === 'admin') {
      getUser(userId).then(r => {
        setUser(r)
        setIsLoading(false)
      })
    } else {
      getSelfUser(userId).then(r => {
        setUser(r)
        setIsLoading(false)
      })
    }
  }

  const handleDisableCard = async (id: string) => {
    setIsLoading(true)
    await disableCard(id)
    refreshUser()
  }

  const handleEnableCard = async (id: string) => {
    setIsLoading(true)
    await enableCard(id)
    refreshUser()
  }

  useEffect(refreshUser, [])

  const userFields = [
    { title: 'Name', render: (u: ResponseUser) => u.name },
    { title: 'Surname', render: (u: ResponseUser) => u.surname },
    { title: 'Nationality', render: (u: ResponseUser) => u.nationality },

    { title: 'Email', render: (u: ResponseUser) => u.email },
    { title: 'Phone Number', render: (u: ResponseUser) => `+${u.phoneCode} ${u.phoneNumber}` },
    { title: 'Emergency Contact', render: (u: ResponseUser) => u.emergencyContact },
    {
      title: 'Address',
      render: (u: ResponseUser) => (
        <CopyButton value={u.address} text={getShortString(u.address)} />
      ),
    },
    { title: 'Country', render: (u: ResponseUser) => u.country },
    {
      title: 'Registration Date',
      render: (u: ResponseUser) => new Date(u.createdAt!).toUTCString(),
    },
  ]

  if (isLoading) {
    return <Loader />
  }

  if (!user || !authUser) {
    return null
  }

  return (
    <>
      <Container mt={12}>
        <Box>User</Box>
        <Heading>
          {user.name} {user.surname}
        </Heading>
        <ContainerBox mt={6}>
          <Heading size="lg" mb={6}>
            Details
          </Heading>
          <SimpleGrid columns={{ base: 1, lg: 3 }} gap={3}>
            {userFields.map(uf => (
              <Box>
                <Box>{uf.title}</Box>
                <Box fontWeight="bold">{uf.render(user)}</Box>
              </Box>
            ))}
          </SimpleGrid>
        </ContainerBox>
        <ContainerBox mt={6}>
          <Heading size="lg" mb={6}>
            Cards
          </Heading>
          {user.cards && user.cards.length === 0 && <Box>No cards to display</Box>}
          {user.cards && user.cards.length > 0 && (
            <Table
              columns={[
                {
                  title: 'ID',
                  render: c => <CopyButton text={getShortString(c.id)} value={c.id} />,
                },
                {
                  title: 'Type',
                  render: c => c.type.name,
                },
                {
                  title: 'Status',
                  render: c => <Box textTransform="capitalize">{c.status}</Box>,
                },
                {
                  title: 'Requested Date',
                  render: c => (
                    <Box textTransform="capitalize">{new Date(c.createdAt).toUTCString()}</Box>
                  ),
                },
                authUser.role === 'admin'
                  ? {
                      title: 'Actions',
                      render: (c: ResponseCard) => (
                        <Flex gap={6}>
                          {c.isDisabled && (
                            <Button
                              onClick={() => handleEnableCard(c.id)}
                              variant="link"
                              colorScheme="green"
                            >
                              Enable
                            </Button>
                          )}
                          {!c.isDisabled && (
                            <ConfirmationButton
                              onConfirm={() => handleDisableCard(c.id)}
                              modalContent="Are you sure you want to disable this card?"
                              variant="link"
                              colorScheme="red"
                            >
                              Disable
                            </ConfirmationButton>
                          )}
                        </Flex>
                      ),
                    }
                  : undefined,
              ]}
              data={user.cards}
            />
          )}
        </ContainerBox>
      </Container>
    </>
  )
}

export default UserPage
