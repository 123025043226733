import React from 'react'
import { Box, ButtonProps, Flex, useDisclosure } from '@chakra-ui/react'
import { Button, Modal } from 'components'

interface Props extends ButtonProps {
  onConfirm: () => void
  modalContent: any
  children: any
}

const ConfirmationButton = ({ onConfirm, modalContent, children, ...rest }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleConfirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <>
      <Button onClick={onOpen} {...rest}>
        {children}
      </Button>

      <Modal title="Confirmation" isOpen={isOpen} onClose={onClose}>
        <Box>{modalContent}</Box>
        <Flex mt={4} gap={3}>
          <Button onClick={handleConfirm}>Confirm</Button>
          <Button variant="secondary" mr={3} onClick={onClose}>
            Cancel
          </Button>
        </Flex>
      </Modal>
    </>
  )
}

export default ConfirmationButton
