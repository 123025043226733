import React, { useState } from 'react'
import { FormControl, FormHelperText, FormLabel, Input } from '@chakra-ui/react'
import { Button, Loader } from 'components'
import { useApi } from 'hooks/useApi'
import { Merchant, ResponseMerchant } from 'types/merchant'

interface Props {
  existingMerchant?: ResponseMerchant
  onSuccess: () => void
}

const MerchantForm = ({ existingMerchant, onSuccess }: Props) => {
  const { createMerchant, updateMerchant } = useApi()

  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState<Merchant>({
    companyName: existingMerchant ? existingMerchant.companyName : '',
  })

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    try {
      setIsLoading(true)
      if (existingMerchant) {
        await updateMerchant(existingMerchant.id, formData)
      } else {
        await createMerchant(formData)
      }
      onSuccess()
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormControl isRequired>
        <FormLabel>Company Name</FormLabel>
        <Input
          type="text"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
        />
        <FormHelperText>Name of the merchant company</FormHelperText>
      </FormControl>

      <Button w="full" type="submit" mt={6}>
        Submit
      </Button>
    </form>
  )
}

export default MerchantForm
