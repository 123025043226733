import React, { useEffect, useState } from 'react'
import {
  Button,
  ConfirmationButton,
  Container,
  ContainerBox,
  Loader,
  Modal,
  Table,
} from 'components'
import { Box, Flex, Heading, useDisclosure } from '@chakra-ui/react'
import { useApi } from 'hooks/useApi'
import CardTypeForm from './components/CardTypeForm'
import { CardType, ResponseCardType } from 'types/card'

const CardTypesPage = () => {
  const { getAllCardTypes, disableCardType, enableCardType } = useApi()
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()
  const [cardTypes, setCardTypes] = useState<ResponseCardType[]>()
  const [editedCardType, setEditedCardType] = useState<ResponseCardType>()
  const [isLoading, setIsLoading] = useState(true)

  const refreshCardTypes = () => {
    setIsLoading(true)
    getAllCardTypes().then(r => {
      setCardTypes(r)
      setIsLoading(false)
    })
  }

  const handleCreate = () => {
    setEditedCardType(undefined)
    onModalOpen()
  }

  const handleEdit = (cardType: ResponseCardType) => {
    setEditedCardType(cardType)
    onModalOpen()
  }

  const handleDisable = async (id: string) => {
    setIsLoading(true)
    await disableCardType(id)
    refreshCardTypes()
  }

  const handleEnable = async (id: string) => {
    setIsLoading(true)
    await enableCardType(id)
    refreshCardTypes()
  }

  useEffect(refreshCardTypes, [])

  return (
    <>
      <Container mt={12}>
        <Heading>Card Types</Heading>
        <Button mt={6} onClick={handleCreate}>
          Add Card Type
        </Button>
        <ContainerBox mt={6}>
          {isLoading && <Loader />}
          {!isLoading && cardTypes && cardTypes.length === 0 && <Box>No card types to display</Box>}
          {!isLoading && cardTypes && cardTypes.length > 0 && (
            <Table
              data={cardTypes.sort((a, b) => (a.isDisabled && !b.isDisabled ? 1 : -1))}
              columns={[
                {
                  title: 'Name',
                  render: (ct: ResponseCardType) => ct.name,
                },
                {
                  title: 'Provider ID',
                  render: (ct: ResponseCardType) => ct.providerCardTypeId,
                },
                {
                  title: 'Application Type',
                  render: (ct: ResponseCardType) => ct.applicationType,
                },
                {
                  title: 'Actions',
                  render: (ct: ResponseCardType) => (
                    <Flex gap={6}>
                      <Button onClick={() => handleEdit(ct)} variant="link">
                        Edit
                      </Button>
                      {ct.isDisabled && (
                        <Button
                          onClick={() => handleEnable(ct.id)}
                          variant="link"
                          colorScheme="green"
                        >
                          Enable
                        </Button>
                      )}
                      {!ct.isDisabled && (
                        <ConfirmationButton
                          onConfirm={() => handleDisable(ct.id)}
                          modalContent="Are you sure you want to disable this card type? It might cause unexpected problems if it is still in use."
                          variant="link"
                          colorScheme="red"
                        >
                          Disable
                        </ConfirmationButton>
                      )}
                    </Flex>
                  ),
                },
              ]}
            />
          )}
        </ContainerBox>
      </Container>
      <Modal title="New Card Type" isOpen={isModalOpen} onClose={onModalClose}>
        <CardTypeForm
          existingCardType={editedCardType}
          onSuccess={() => {
            onModalClose()
            refreshCardTypes()
          }}
        />
      </Modal>
    </>
  )
}

export default CardTypesPage
