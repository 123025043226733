import React from 'react'
import { Box, CloseButton, Flex, Image, Text, BoxProps } from '@chakra-ui/react'
import SidebarItem from './SidebarItem'
import { useAuth } from 'providers/AuthProvider'
import { Link } from 'react-router-dom'

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ onClose }: SidebarProps) => {
  const { user } = useAuth()

  if (!user) {
    return null
  }

  const managerItems = [
    {
      to: '/',
      label: 'Dashboard',
      icon: 'fas fa-gauge',
    },
    {
      to: '/users',
      label: 'Users',
      icon: 'fas fa-user',
    },
    {
      to: '/card-fees',
      label: 'Cards & Fees',
      icon: 'fas fa-credit-card',
    },
    {
      to: '/api',
      label: 'API',
      icon: 'fas fa-code',
    },
  ]

  const adminItems = [
    {
      to: '/',
      label: 'Dashboard',
      icon: 'fas fa-gauge',
    },
    {
      to: '/merchants',
      label: 'Merchants',
      icon: 'fas fa-shop',
    },
    {
      to: '/card-types',
      label: 'Card Types',
      icon: 'fas fa-credit-card',
    },
  ]

  const items = user.role === 'admin' ? adminItems : managerItems

  return (
    <Box bg="whiteAlpha.700" w={{ base: 'full', lg: 72 }} position="fixed" h="full">
      <Flex alignItems="center" p={8} justifyContent="space-between">
        <Image src="/img/logo.svg" h="40px" />
        <CloseButton onClick={onClose} display={{ base: 'flex', lg: 'none' }} />
      </Flex>
      {items.map(i => (
        <Link to={i.to}>
          <Flex
            align="center"
            p={4}
            mx={4}
            cursor="pointer"
            _hover={{
              color: 'purple.500',
            }}
          >
            <Box className={i.icon} w={8} />
            <Box>{i.label}</Box>
          </Flex>
        </Link>
      ))}
    </Box>
  )
}

export default SidebarContent
