import { useToast } from '@chakra-ui/react'
import { writeContract, waitForTransaction } from '@wagmi/core'
import treasuryAbi from 'data/abi/Treasury.json'
import { useTokenApproval } from './useTokenApproval'

interface Props {
  tokenAddress: string
  treasuryAddress: string
}

export const useTreasuryContract = ({ tokenAddress, treasuryAddress }: Props) => {
  const toast = useToast()
  const { approveIfNotApproved } = useTokenApproval({ tokenAddress })

  const deposit = async (amount: bigint, reference: string) => {
    try {
      await approveIfNotApproved(treasuryAddress as any, amount)
      const { hash } = await writeContract({
        address: treasuryAddress as any,
        abi: treasuryAbi,
        functionName: 'deposit',
        args: [tokenAddress, amount, reference],
      })
      await waitForTransaction({ hash })
    } catch (err: any) {
      console.error(err)
      toast({ status: 'error', title: 'Transaction failed', description: err.reason })
      throw new Error()
    }
  }

  return { deposit }
}
