import React, { useEffect, useState } from 'react'
import { FormControl, FormHelperText, FormLabel, Input, Select, Stack } from '@chakra-ui/react'
import { Button, Loader } from 'components'
import { useApi } from 'hooks/useApi'
import { CardFee, ResponseCardFee, ResponseCardType } from 'types/card'

interface Props {
  merchantId: string
  existingCardFee?: ResponseCardFee
  onSuccess: () => void
}

const CardFeeForm = ({ merchantId, existingCardFee, onSuccess }: Props) => {
  const { createCardFee, updateCardFee, getAllCardTypes } = useApi()

  const [cardTypes, setCardTypes] = useState<ResponseCardType[]>()
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState<CardFee>({
    applicationFeeUsd: existingCardFee ? `${existingCardFee.applicationFeeUsd / 100}` : '',
    rechargeFeeUsd: existingCardFee ? `${existingCardFee.rechargeFeeUsd / 100}` : '',
    cardTypeId: existingCardFee ? existingCardFee.cardType.id : '',
    merchantId,
  })

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    try {
      setIsLoading(true)
      if (existingCardFee) {
        await updateCardFee(existingCardFee.id, {
          ...formData,
          applicationFeeUsd: parseInt((parseFloat(formData.applicationFeeUsd) * 100).toFixed(0)),
          rechargeFeeUsd: parseInt((parseFloat(formData.rechargeFeeUsd) * 100).toFixed(0)),
        })
      } else {
        await createCardFee({
          ...formData,
          applicationFeeUsd: parseInt((parseFloat(formData.applicationFeeUsd) * 100).toFixed(0)),
          rechargeFeeUsd: parseInt((parseFloat(formData.rechargeFeeUsd) * 100).toFixed(0)),
        })
      }
      onSuccess()
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  useEffect(() => {
    setIsLoading(true)
    getAllCardTypes().then(r => {
      setCardTypes(r)
      setIsLoading(false)
    })
  }, [])

  if (isLoading || !cardTypes) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={4}>
        <FormControl isRequired>
          <FormLabel>Card Type</FormLabel>
          <Select
            placeholder="Select option"
            name="cardTypeId"
            value={formData.cardTypeId}
            isDisabled={!!existingCardFee}
            onChange={handleChange}
          >
            {cardTypes.map(ct => (
              <option value={ct.id}>
                {ct.name} ({ct.providerCardTypeId})
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Application Fee (USD)</FormLabel>
          <Input
            type="text"
            name="applicationFeeUsd"
            value={formData.applicationFeeUsd}
            onChange={handleChange}
          />
          <FormHelperText>Indicate the fee in USD</FormHelperText>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Recharge Fee (USD)</FormLabel>
          <Input
            type="text"
            name="rechargeFeeUsd"
            value={formData.rechargeFeeUsd}
            onChange={handleChange}
          />
          <FormHelperText>Indicate the fee in USD</FormHelperText>
        </FormControl>

        <Button w="full" type="submit" mt={6}>
          Submit
        </Button>
      </Stack>
    </form>
  )
}

export default CardFeeForm
